import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import saayaLogo from '../Assets/Images/saaya logo.svg'
import appstore from '../Assets/Images/appstore.png'
import playstore from '../Assets/Images/playstore.png'
import phone from '../Assets/Images/Wellness Portal Button Icons/phone-flip 2.svg'
import video from '../Assets/Images/Wellness Portal Button Icons/video-camera-alt 2.svg'
import whatsapp from '../Assets/Images/Wellness Portal Button Icons/whatsapp 1.svg'
import english from '../Assets/Images/english.svg'
import resourceIcon from '../Assets/Images/Wellness Portal Button Icons/Resourses.svg';
import whoIcon from '../Assets/Images/Wellness Portal Button Icons/WHO5.svg';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ModalVideo from 'react-modal-video'
import Swal from 'sweetalert2'
import { Blogs, Languages, YTlinks, Images, Constants, ToolBox } from '../Helper'
import LinksModal from '../Components/LinksModal'
import MentalHealth from '../Assets/Announcement - World Mental Health Day Saaya.pdf'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { constants } from '../Utils/constants'
import Testimonialscroller from '../Components/TestimonialScroller';
import { testimonials as testimonialsData } from '../Helper';
import { pushToDataLayer } from '../Utils/analytics'

export default function LandingPage() {
    const [isOpen, setOpen] = useState(false)
    const [value, setValue] = useState('English');
    const [country, setCountry] = useState(english);
    const [code, setCode] = useState('');
    const accessCode = 'aramco'
    const [data, setData] = useState(Blogs.english);
    const [toolbox, setToolBox] = useState(ToolBox.english)
    const [show, setShow] = useState(false);
    const [linksLang, setLinksLang] = useState(YTlinks.english);
    const [embedId, setEmbedId] = useState();
    const [current_lang, setCurrentLang] = useState(Constants.english);
    const [openLinkModal, setOpenLinkModal] = useState(false);
    const [filters, setFilters] = useState("")
    const [filtersOption, setFiltersOption] = useState([current_lang.videos_txt, current_lang.blogs_txt, current_lang.toolbox_talks]);
    const [testimonials, setTestimonials] = useState(testimonialsData.english);

    const codeChecker = (e) => {
        pushToDataLayer(constants.ANALYTICS_EVENTS.LOGIN_BTN_CLICKED);
        if (code.length !== 0) {
            let trimmedCode = code.trim()
            if (trimmedCode.toLowerCase() === accessCode.toLowerCase()) {
                setShow(true);
                pushToDataLayer(constants.ANALYTICS_EVENTS.ACCESS_CODE_SUCCESS);
            } else {
                alert('Invalid Access Code');
                pushToDataLayer(constants.ANALYTICS_EVENTS.ACCESS_CODE_FAILURE);
            }
        }
    }

    useEffect(() => {
        if (!show) {
            pushToDataLayer(constants.ANALYTICS_EVENTS.LGOIN_PAGE_VISIT);
        }
        else {
            pushToDataLayer(constants.ANALYTICS_EVENTS.LANDING_PAGE_VISIT);
        }
    }, [show]);

    const filterChangeHandler = (selectedFilter) => {
        pushToDataLayer(constants.ANALYTICS_EVENTS.FILTER_SELECTED, {
            selected_filter: selectedFilter,
        });
        setFilters(selectedFilter);
        if (selectedFilter === current_lang.videos_txt) {
            let tempData = []
            setData([]);
            setToolBox([])
            if (value === 'English') {
                YTlinks.english.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'اردو') {
                YTlinks.urdu.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'عربى') {
                YTlinks.arabic.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'বাংলা') {
                YTlinks.bangali.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'हिन्दी') {
                YTlinks.hindi.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === 'Tagalog') {
                YTlinks.tagalog.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            } else if (value === '中国人') {
                YTlinks.chinese.forEach(data => !data.link && tempData.push(data))
                setLinksLang(tempData)
            }
        } else if (selectedFilter === current_lang.blogs_txt) {
            setLinksLang([]);
            setToolBox([])
            if (value === 'English') {
                setData(Blogs.english)
            } else if (value === 'اردو') {
                setData(Blogs.urdu)
            } else if (value === 'عربى') {
                setData(Blogs.arabic)
            } else if (value === 'বাংলা') {
                setData(Blogs.bangali)
            } else if (value === 'हिन्दी') {
                setData(Blogs.hindi)
            } else if (value === 'Tagalog') {
                setData(Blogs.tagalog)
            } else if (value === '中国人') {
                setData(Blogs.chinese)
            }
        } else if (selectedFilter === current_lang.toolbox_talks) {
            setLinksLang([]);
            setData([])
            if (value === 'English') {
                setToolBox(ToolBox.english)
            } else if (value === 'اردو') {
                setToolBox(ToolBox.english)
            } else if (value === 'عربى') {
                setToolBox(ToolBox.english)
            } else if (value === 'বাংলা') {
                setToolBox(ToolBox.english)
            } else if (value === 'हिन्दी') {
                setToolBox(ToolBox.english)
            } else if (value === 'Tagalog') {
                setToolBox(ToolBox.english)
            } else if (value === '中国人') {
                setToolBox(ToolBox.english)
            }
        } else {
            if (value === 'English') {
                setToolBox(ToolBox.english)
                setData(Blogs.english)
                setLinksLang(YTlinks.english)
                setCurrentLang(Constants.english)
                setTestimonials(testimonialsData.english);
            } else if (value === 'اردو') {
                setToolBox(ToolBox.english)
                setData(Blogs.urdu)
                setLinksLang(YTlinks.urdu)
                setCurrentLang(Constants.urdu)
                setTestimonials(testimonialsData.urdu);
            } else if (value === 'عربى') {
                setToolBox(ToolBox.english)
                setData(Blogs.arabic)
                setLinksLang(YTlinks.arabic)
                setCurrentLang(Constants.arabic)
                setTestimonials(testimonialsData.arabic);
            } else if (value === 'বাংলা') {
                setToolBox(ToolBox.english)
                setData(Blogs.bangali)
                setLinksLang(YTlinks.bangali)
                setCurrentLang(Constants.bangali)
                setTestimonials(testimonialsData.bangali);
            } else if (value === 'हिन्दी') {
                setToolBox(ToolBox.english)
                setData(Blogs.hindi)
                setLinksLang(YTlinks.hindi)
                setCurrentLang(Constants.hindi)
                setTestimonials(testimonialsData.hindi);
            } else if (value === 'Tagalog') {
                setToolBox(ToolBox.english)
                setData(Blogs.tagalog)
                setLinksLang(YTlinks.tagalog)
                setCurrentLang(Constants.tagalog)
                setTestimonials(testimonialsData.tagalog);
            } else if (value === '中国人') {
                setToolBox(ToolBox.english)
                setData(Blogs.chinese)
                setLinksLang(YTlinks.chinese)
                setCurrentLang(Constants.chinese)
                setTestimonials(testimonialsData.chinese);
            }
        }
    }

    useEffect(() => {
        setFiltersOption([current_lang.videos_txt, current_lang.blogs_txt, current_lang.toolbox_talks])
    }, [current_lang])

    const handleChange = (item) => {
        pushToDataLayer(constants.ANALYTICS_EVENTS.LANGUAGE_SELECTED, {
            selected_language: item.name,
        })
        setValue(item.name)
        setCountry(item.image)
        setFilters("");
        if (item.name === 'English') {
            setData(Blogs.english)
            setLinksLang(YTlinks.english)
            setToolBox(ToolBox.english)
            setCurrentLang(Constants.english)
            setTestimonials(testimonialsData.english);
        } else if (item.name === 'اردو') {
            setToolBox(ToolBox.english)
            setData(Blogs.urdu)
            setLinksLang(YTlinks.urdu)
            setCurrentLang(Constants.urdu)
            setTestimonials(testimonialsData.urdu);
        } else if (item.name === 'عربى') {
            setToolBox(ToolBox.english)
            setData(Blogs.arabic)
            setLinksLang(YTlinks.arabic)
            setCurrentLang(Constants.arabic)
            setTestimonials(testimonialsData.arabic);
        } else if (item.name === 'বাংলা') {
            setData(Blogs.bangali)
            setToolBox(ToolBox.english)
            setLinksLang(YTlinks.bangali)
            setCurrentLang(Constants.bangali)
            setTestimonials(testimonialsData.bengali);
        } else if (item.name === 'हिन्दी') {
            setToolBox(ToolBox.english)
            setData(Blogs.hindi)
            setLinksLang(YTlinks.hindi)
            setCurrentLang(Constants.hindi)
            setTestimonials(testimonialsData.hindi);
        } else if (item.name === 'Tagalog') {
            setToolBox(ToolBox.english)
            setData(Blogs.tagalog)
            setLinksLang(YTlinks.tagalog)
            setCurrentLang(Constants.tagalog)
            setTestimonials(testimonialsData.tagalog);
        } else if (item.name === '中国人') {
            setData(Blogs.chinese)
            setLinksLang(YTlinks.chinese)
            setCurrentLang(Constants.chinese)
            setTestimonials(testimonialsData.chinese);
        }
    };

    const imgAction = (item) => {
        const img = item.image_hd
        pushToDataLayer(constants.ANALYTICS_EVENTS.CLICKED_RESOURCE, {
            resource_link: img,
            resource_language: value,
            resource_name: item.title
        })
        window.open(img, '_blank');
    }

    window.onbeforeunload = function () {
        setCode('')
        setShow(false)
        document.cookie = "accessCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    const handleVidOpen = (item) => {
        const id = item.embedId;
        pushToDataLayer(constants.ANALYTICS_EVENTS.CLICKED_RESOURCE,{
            resource_link: `https://img.youtube.com/vi/${id}/hqdefault.jpg`,
            resource_language: value,
            resource_name: item.title   
        })
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        setOpen(true)
        setEmbedId(id)
    }

    const handleVidClose = () => {
        setOpen(false)
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.height = '';
        body.style.overflowY = '';
    }

    return (
        <div className='main hero-image'>
            <Container fluid className='container_main'>
                <Row className='hero notVisible'>
                    <div className={`bgDiv transitionSize ${!show && 'fullScreenTemp '}`}>
                    </div>
                    <div className='saayaLogo posAbs'>
                        <img className={`${show ? 'opacityVisibleWithTransition' : 'opacityZero'}`} src={saayaLogo} alt='img22' />
                    </div>
                    <Col xs={12} className='height570 displayFlex centerheight heading ml0'>
                        {show ?
                            <>
                                <p className='header'>{current_lang.heading}</p>
                                <p className='secondary'>{current_lang.subHeading}</p>
                                <Testimonialscroller testimonials={testimonials} />
                            </>
                            :
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className='fontSize22'>Enter Access code</label>
                                <input
                                    autoComplete='new-password'
                                    autoFocus
                                    value={code}
                                    onKeyPress={e => e.key === 'Enter' && codeChecker(code)}
                                    onClick={() => window.scrollTo(0, 0)}
                                    onChange={(e) => setCode(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter Code"
                                />
                                <button disabled={code.length === 0} className="btn btn-primary" onClick={() => codeChecker()}>Enter</button>
                            </div>
                        }
                    </Col>
                </Row>

            </Container>
            {show ?
                <>
                    {/* <Container className='banner-container'>
                        <Row className='mt-4 mt-md-0 mb-4 p-4 bg-black bg-opacity-75 text-light'>
                            <Col>
                                <p className="text-center">
                                    {constants.BANNER_TXT_MSG}
                                    <a
                                        href="https://wellness.saayahealth.com/"
                                        target="_blank"
                                        onClick={() => logEvent(analytics, constants.CLICK_HERE_CLICKED_TXT, { btn_type: 'schedule a video session' })}
                                    >
                                        {constants.CLICK_HERE_TXT}
                                    </a>
                                    {constants.WHATSAPP_MSG}
                                    <a
                                        href="https://api.whatsapp.com/message/KJB7UMMYXUFEO1"
                                        target="_blank"
                                        onClick={() => logEvent(analytics, constants.CLICK_HERE_CLICKED_TXT, { btn_type: 'WhatsApp message' })}
                                    >
                                        {constants.CLICK_HERE_TXT}
                                    </a>.
                                </p>
                            </Col>
                        </Row>
                    </Container> */}

                    <Container className='buttonContainer banner-container'>
                        {openLinkModal &&
                            <LinksModal
                                setOpenLinkModal={setOpenLinkModal}
                                webPortal={current_lang.web_portal}
                                android={current_lang.android_portal}
                                ISO={current_lang.iOS_portal}
                            />}
                        <div className='my-center-container'>
                            <Row className='buttonRow'>
                                <Col sm className='buttonColumn my-btn-container'>
                                    <Button
                                        onClick={() => {
                                            Swal.fire({
                                                text: current_lang.contact_us,
                                                confirmButtonText: current_lang.okay
                                            });
                                            pushToDataLayer(constants.ANALYTICS_EVENTS.CONTACT_US_BTN_CLICKED);
                                        }}>
                                        <Image src={phone} />
                                        {current_lang.hotline_btn}
                                    </Button>
                                    <a
                                        onClick={(e) => {
                                            pushToDataLayer(constants.ANALYTICS_EVENTS.VIDEO_CALL_BTN_CLICKED);
                                            setOpenLinkModal(true);
                                        }}
                                        rel="noreferrer noopener"
                                    >
                                        <Button>
                                            <Image src={video} />
                                            {current_lang.vid_call_btn}
                                        </Button>
                                    </a>
                                    <a rel="noreferrer noopener" target="_blank" href='https://wa.me/message/KJB7UMMYXUFEO1'
                                        onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.WHATSAPP_BTN_CLICKED)}>
                                        <Button>
                                            <Image src={whatsapp} />
                                            {current_lang.whatsApp_btn}
                                        </Button>
                                    </a>
                                    <a rel="noreferrer noopener" target="_blank" href='https://www.saayahealth.com/resources'
                                        onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.RESOURCES_BTN_CLICKED)}
                                    >
                                        <Button>
                                            <Image src={resourceIcon} />
                                            {current_lang.resources_btn}
                                        </Button>
                                    </a>
                                    <a rel="noreferrer noopener" target="_blank" href='https://form.typeform.com/to/NB5Ik0mo?typeform-source=github.com'
                                        onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.WHO_BTN_CLICKED)}
                                    >
                                        <Button>
                                            <Image src={whoIcon} />
                                            {current_lang.Who_btn}
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                            <Row className='dropdownRow w-75'>
                                <div className="dropdown">
                                    {/* conditinal classname if value contains any value from array */}

                                    <Row className='buttonRow border-1'>
                                        <Col className={`buttonColumn dropdown-container ${['عربى', 'اردو'].includes(value) ? 'flex-row-reverse' : ''}`}>
                                            <Dropdown>
                                                <div className={`labelLanguage ${['عربى', 'اردو'].includes(value) ? 'alignRight' : ''}`}>{current_lang.choose_lang}</div>
                                                <Dropdown.Toggle className='toggle' variant="success" id="dropdown-basic">
                                                    {value === '' ? 'Select Language' : (
                                                        <>
                                                            <div className='toggleDiv'>
                                                                <Image width={'30px'} src={country} /> {value}
                                                            </div>
                                                        </>
                                                    )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {Languages?.map((item, index) => (
                                                        <Dropdown.Item onClick={(e) => handleChange(item, e)} key={'111' + index + item}>
                                                            <Image width={'30px'} src={item.image} /> {item.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Dropdown>
                                                <div className={`labelLanguage ${['عربى', 'اردو'].includes(value) ? 'alignRight' : ''}`}>{current_lang.choose_cat}</div>
                                                <Dropdown.Toggle className='toggle' variant="success" id="dropdown-basic">
                                                    {filters === '' ? current_lang.select_category : (
                                                        <>
                                                            <div className='toggleDiv'>
                                                                {filters}
                                                            </div>
                                                        </>
                                                    )}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {filters && <Dropdown.Item onClick={(e) => filterChangeHandler("", e)}>{current_lang.none_txt}</Dropdown.Item>}
                                                    {filtersOption?.map((item, index) => (
                                                        <Dropdown.Item onClick={(e) => filterChangeHandler(item, e)} key={'111' + index + item}>
                                                            {item}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </div>
                        <Row className='cardRow d-grid'>
                            {linksLang?.map((item, index) => (
                                item.embedId ?
                                    <Col key={item + index}>
                                        <div
                                            onClick={() => handleVidOpen(item)}
                                            className="card bg-dark text-white min100"
                                        >
                                            <img
                                                className='card-img pointerCursor'
                                                src={`https://img.youtube.com/vi/${item.embedId}/hqdefault.jpg`}
                                            />
                                            <div className="card-img-overlay">
                                                <h5 className="card-title">
                                                    <img className='ytIconImg' src={Images.yt_icon} />{item.title}
                                                </h5>
                                            </div>
                                        </div>
                                    </Col>
                                    : <></>
                            )).reverse()}

                            {linksLang?.map((item, index) => (
                                item.link ?
                                    <Col key={'222' + item + index + item.title}>
                                        <div
                                            // Inline Style Background Color with
                                            onClick={() => window.open(item.link, '_blank')}
                                            className="urlIcon card text-white min100 "
                                        >
                                            <img
                                                className='card-img pointerCursor '
                                                src={item.dImg}
                                            />
                                            <div className="card-img-overlay">
                                                <h5 className="card-title">
                                                    {item.title}
                                                </h5>
                                            </div>
                                        </div>
                                    </Col>
                                    : <></>
                            )).reverse()}

                            {data?.map((item, index) => (
                                <Col key={'333' + item + index}>
                                    <div className="card bg-dark text-white min100" onClick={() => imgAction(item)}>
                                        <img className='card-img' src={item.image} alt={item.title} />
                                        <div className="card-img-overlay">
                                            <h5 className="card-title">{item.title}</h5>
                                        </div>
                                    </div>
                                </Col>
                            )).reverse()}

                            {toolbox?.map((item, index) => (
                                <Col key={'333' + item + index}>
                                    <div className="card text-white min100" onClick={() => imgAction(item)}>
                                        <img className='card-img' src={item.image} alt={item.title} />
                                        <div className="card-img-overlay">
                                            <h5 className="card-title">{item.title}</h5>
                                        </div>
                                    </div>
                                </Col>
                            )).reverse()}


                            <div className={`video-responsive ${!isOpen && 'hide'}`}>
                                <ModalVideo
                                    channel='youtube'
                                    autoplay={true}
                                    isOpen={isOpen}
                                    videoId={embedId}
                                    onClose={() => handleVidClose()}
                                />
                            </div>
                        </Row>
                        {/* <Row className='moreBlogRow'>
                        <div className="col-12 text-center my-4">
                            <a href="https://saayahealth.com/index.php/saaya-blog" className="btn mb-md-5"><span className="text-light">More Blogs</span></a>
                        </div>
                    </Row> */}
                    </Container>
                    <Container>
                        <Row>
                            <Col className='icons-container'>
                                <a target="_blank" href='https://apps.apple.com/pk/app/contractor-well-being-program/id1627179382'>
                                    <Image
                                        className='app-icon app-store'
                                        src={appstore}
                                    />
                                </a>
                                <a target="_blank" href='https://play.google.com/store/apps/details?id=cwp.saaya.android'>
                                    <Image
                                        className='app-icon play-store'
                                        src={playstore}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </>
                : (<></>)}
        </div>
    )
}