import { Button, Col, Row, Image } from "react-bootstrap"
import closeIcon from "../Assets/Images/close-icon.png"
import appStore from "../Assets/Images/appStore.svg"
import playStore from "../Assets/Images/playStore.svg"
import website from "../Assets/Images/website.svg"
import { constants } from "../Utils/constants"
import { pushToDataLayer } from "../Utils/analytics"

export default function LinksModal({ setOpenLinkModal, webPortal, android, ISO }) {
    return (
        <div className="modal-container">
            <div className="modal-container" onClick={()=>setOpenLinkModal(false)} ></div>
            <Row className='buttonRow linkModal'>
                <span className="close-icon" onClick={() => setOpenLinkModal(false)}><img src={closeIcon}/></span>
                <Col className='buttonColumn d-flex flex-column modal-buttons-container'>
                    <a rel="noreferrer noopener" target="_blank" href='https://cwp.saayahealth.com' className="modal-buttons">
                        <Button
                            className="modal-button"
                            onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.USE_BROWSER_LINK_BTN)}
                        >
                            <Image
                                src={website}
                            />
                            {webPortal}
                        </Button>
                    </a>

                    <a rel="noreferrer noopener" target="_blank" href='https://play.google.com/store/apps/details?id=cwp.saaya.android' className="modal-buttons">
                        <Button
                            className="modal-button"
                            onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.DOWNLOAD_APP_LINK_BTN)}
                        >
                            <Image
                                src={playStore}
                            />
                            {android}
                        </Button>
                    </a>

                    <a rel="noreferrer noopener" target="_blank" href='https://apps.apple.com/pk/app/contractor-well-being-program/id1627179382' className="modal-buttons">
                        <Button
                            className="modal-button"
                            onClick={() => pushToDataLayer(constants.ANALYTICS_EVENTS.DOWNLOAD_IOS_APP_LINK_BTN)}
                        >
                            <Image
                                src={appStore}
                            />
                            {ISO}
                        </Button>
                    </a>
                </Col>
            </Row>
        </div>
    )
}