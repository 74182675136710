import TagManager from 'react-gtm-module';

const initializeGTM = () => {
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    }
    TagManager.initialize(tagManagerArgs);
  }
};

const pushToDataLayer = (event, data = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data,
    },
  });
};

export { initializeGTM, pushToDataLayer };