import React, { useState, useEffect, useRef } from 'react';
import quotationIcon from '../../Assets/Images/qutation-icon.svg';

const TestimonialScroller = ({ testimonials }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const containerRef = useRef(null);
    const extendedTestimonials = [...testimonials, ...testimonials];

    useEffect(() => {
        const updateCardWidth = () => {
            if (window.innerWidth > 700) {
                setCardWidth(400);
            } else {
                if (containerRef.current) {
                    setCardWidth(containerRef.current.offsetWidth);
                }
            }
        };
        updateCardWidth();
        window.addEventListener('resize', updateCardWidth);
        return () => window.removeEventListener('resize', updateCardWidth);
    }, []);

    useEffect(() => {
        if (!testimonials || testimonials.length <= 1) return;

        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [testimonials]);

    const handleDotClick = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setActiveIndex(index);
    };

    const handleTransitionEnd = () => {
        if (activeIndex >= testimonials.length) {
            setActiveIndex(0); 
        }
    };

    return (
        <div className="testimonial-scroller" ref={containerRef}>
            <div className="carousel-wrapper">
                <div
                    className="carousel-container"
                    style={{
                        transform: `translateX(-${activeIndex * cardWidth}px)`,
                        transition: activeIndex >= testimonials.length ? 'none' : 'transform 0.5s ease-in-out',
                        width: `${extendedTestimonials.length * cardWidth}px`,
                    }}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {extendedTestimonials.map((testimonial, index) => (
                        <div key={index} className="content-container" style={{ width: `${cardWidth}px` }}>
                            <div className="testimonial-content">
                                <img src={quotationIcon} width={14} alt="Quotation Icon" />
                                <span className="testimonial-text">{testimonial.text}</span>
                                <span className="anonymous-user-text">
                                    {testimonial.user}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="dot-navigation">
                {testimonials.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${activeIndex % testimonials.length === index ? 'active' : ''}`}
                        onClick={(e) => handleDotClick(e, index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default TestimonialScroller;
