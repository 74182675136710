import './App.css';
import LandingPage from './Container/Landing-Page';
import './Assets/Style/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initializeGTM } from './Utils/analytics';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      initializeGTM();
    }
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        <LandingPage />
      </header>
    </div>
  );
}

export default App;
